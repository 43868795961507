.blanket.search-drawer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:500;
}

.SearchDrawer {
  position: absolute;
  top: 0;
  /* left: calc( 100% - 400px ); */
  right: 0;
  bottom: 0;
  padding: 20px;
  max-height: 100vh;
  background: var(--default-color-background);
  z-index:550;
  box-shadow: var( --neutral-drawer-shadow );
}

.SearchDrawer .search-result-list {
  max-height: calc( 100vh - 200px );
  overflow: auto;
}
