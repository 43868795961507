#root,
.AlohaApp,
.layout {
  width: 100vw;
  height: 100vh;
}
.layout {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}
main {
  flex: 1; /* grow */
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
}
main > article {
  flex: 1; /* grow */
}

.MainHeader {
  border-bottom: var(--neutral-narrow-border);
  position: relative; /** for EditingBlanket */
}
.MainHeader .search-button {
  display: none;
}

@media screen and (max-width: 600px) {
  .MainHeader .navbar {
    padding: 0.5rem;
  }
  .MainHeader .create-content-dropdown {
    display: none;
  }
  .MainHeader .search-form {
    display: none;
  }
  .MainHeader .search-button {
    display: block;
  }
}

.MainHeader a.navbar-brand {
  width: 150px;
}
.MainHeader .navbar-brand img {
  width: auto;
}
.MainHeader .dropdown-menu {
  margin-top: 5px;
}
@media screen and (min-width: 768px) {
  .MainHeader a.navbar-brand.unauthed {
    margin-left: 0.75rem;
  }
  .MainHeader a.login-button {
    margin-right: 0.75rem;
  }
  .MainHeader .create-dropdown-menu {
    right: 0;
    left: auto;
  }
}
.create-dropdown-menu .dropdown-header {
  font-size: 1rem;
}
.create-content-dialog .modal-body {
  overflow: visible;
}
.create-content-dialog .modal-body > div {
  overflow: visible;
  padding: 0;
}
.create-content-dialog .modal-body .btn-groups {
  min-width: 0;
}

.create-content-dropdown .dropdown-toggle::after {
  margin-left: 0.4em;
  vertical-align: 0.1em;
}
#createTrigger:hover {
  cursor: pointer;
}
#createTrigger:hover span {
  margin-right:0px;
  opacity: 1;
  width: auto;
  max-width:500px; /* this value makes the transition */
  padding-right:38px;
}

#profileTrigger img {
  width: 32px;
  height: 32px;
  background-color: var(--default-color-tint-background);
}
.MenuDrawer-trigger {
  font-size: 20px;
  color: var(--default-color-foreground);
}
.MenuDrawer-trigger:hover {
  color: var(--default-color-foreground);
}
.MenuDrawer-trigger:focus {
  box-shadow: none;
}
.MenuDrawer-trigger .fa {
  transition: all 0.2s;
}

.MenuDrawer-trigger .fa-times {
  opacity: 0;
  margin-left: -16px;
}

.MenuDrawer-trigger.open .fa-times {
  opacity: 1;
  transform: rotate(180deg);
}

.MenuDrawer-trigger .fa-bars {
  opacity: 1;
}

.MenuDrawer-trigger.open .fa-bars {
  opacity: 0;
  transform: rotate(180deg);
}

.MainFooter {
  position: relative; /** for EditingBlanket */
}

html .container {
  max-width: 100%;
}

@media (min-width: 1200px) {
  html .container {
    max-width: 1440px;
  }
  html .x-section .container {
    max-width: 1800px;
  }
}

/* minor layout considerations */
/* #layout {
  margin-bottom: 90px; // for the palette
} */

.fullWidth {
  padding-top: 3em;
  padding-bottom: 3em;
  max-width: 100vw;
}

.tight {
  margin: 0;
  padding: 0;
}

/* For TightGrid component only*/

.TightGrid .col {
  position: relative;

  padding-top: 15px;
  padding-bottom: 15px;
}

.TightGrid .col::before {
  padding-top: 100%;
  display: block;
  content: '';
}

.TightGrid .col div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
}

.-no-overflow {
  overflow: hidden;
}

/* `.xxx-list.droppable` - hshould have a min-height so that it's possible to see whewre you can drop stuff */
.droppable {
  min-height: 200px;
}

/* Modal */
/*
.modal-dialog .modal-footer {
  border-top: none;
}
*/
.modal-backdrop.show {
  opacity: 1;
  z-index: 900;
}

/* Table */
.AlohaApp .table td,
.table th {
  vertical-align: middle;
}

/* Forms */
.icon-required:before {
  content: '*';
  left: 0;
  top: 0;
  text-indent: 0;
  color: #de350b;
  line-height: 1;
  font-size: 15px;
  margin-left: 2px;
}

.input-long {
  max-width: 500px;
}
.page-section .row {
  margin: 0;
}
.page-header-block {
  padding: 0.75rem 0rem;
  margin-bottom: 0;
}
.page-body-block {
  flex: 1 1 auto;
  padding: 1.25rem;
}
@media (max-width: 768px) {
  .page-body-block>.row>.col-12,
  .page-header-block>.title-block>.row>.col-12 {
      padding: 0;
  }
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}
.pl-7,
.px-7 {
  padding-left: 5rem !important;
}
.pl-8,
.px-8 {
  padding-left: 6rem !important;
}
.pl-9,
.px-9 {
  padding-left: 7rem !important;
}
.pl-10,
.px-10 {
  padding-left: 8rem !important;
}
.pr-6,
.px-6 {
  padding-right: 4rem !important;
}
.pr-7,
.px-7 {
  padding-right: 5rem !important;
}
.pr-8,
.px-8 {
  padding-right: 6rem !important;
}
.pr-9,
.px-9 {
  padding-right: 7rem !important;
}
.pr-10,
.px-10 {
  padding-right: 8rem !important;
}

/* Bootstrap Callout */
.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}
.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.bs-callout p:last-child {
  margin-bottom: 0;
}
.bs-callout code {
  border-radius: 3px;
}
.bs-callout + .bs-callout {
  margin-top: -5px;
}
.bs-callout-default {
  border-left-color: #777;
}
.bs-callout-default h4 {
  color: #777;
}
.bs-callout-primary {
  border-left-color: #428bca;
}
.bs-callout-primary h4 {
  color: #428bca;
}
.bs-callout-success {
  border-left-color: #5cb85c;
}
.bs-callout-success h4 {
  color: #5cb85c;
}
.bs-callout-danger {
  border-left-color: #d9534f;
}
.bs-callout-danger h4 {
  color: #d9534f;
}
.bs-callout-warning {
  border-left-color: #f0ad4e;
}
.bs-callout-warning h4 {
  color: #f0ad4e;
}
.bs-callout-info {
  border-left-color: #5bc0de;
}
.bs-callout-info h4 {
  color: #5bc0de;
}
