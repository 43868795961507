.page-item.pagination {
  font-size: 13px;
}

/** the root element receives the `prefixCls` class, so we have to reset some selectors... **/
.pagination .page-item:last-child .page-link,
.pagination .page-item:first-child .page-link,
.page-item .page-link {
  border-radius: 0 !important;
  margin-left: -1px !important;
}

.pagination .page-item.-prev > .page-link {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.pagination .page-item.-next > .page-link {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

/* .pagnination li.page-item + li.page-item {
  margin-left: -1px;
} */

/** incorrectselectors need to receive the correct styles **/

/* fix for using `.-item-active` rather than `.active` */
.page-item.-item-active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

/* fix for using `.-disabled` rather than `.disabled` */
.page-item.-disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
