:root {
  /* set default colors for dark & light colors - not changed by javascript */
  --palette-dark: rgba(0, 0, 0, 1);
  --palette-light: rgba(255, 255, 255, 1);

  /* set defaults for off-white or off-black tints */
  --dark-tint: rgba(0, 0, 0, 0.075);
  --light-tint: rgba(255, 255, 255, 0.15);
  --dark-tint-2x: rgba(0, 0, 0, 0.15);
  --light-tint-2x: rgba(255, 255, 255, 0.3);

  /* set defaults for light & dark & tint */
  --default-color-background: var(--palette-light);
  --default-color-foreground: var(--palette-dark);
  --default-color-tint-background: var(--dark-tint);
  --default-color-tint-2x-background: var(--dark-tint-2x);
  --default-color-neutral: rgba(128, 128, 128, 0.8);

  /* set defaults for primary, secondary, tertiary & quaternary palette colors */
  /* THESE ARE CHANGED BY JAVASCRIPT */
  --palette-color-primary: #fff;
  --palette-color-primary-contrast: #000;
  --palette-color-secondary: #fff;
  --palette-color-secondary-contrast: #000;
  --palette-color-tertiary: #fff;
  --palette-color-tertiary-contrast: #000;
  --palette-color-quaternary: #fff;
  --palette-color-quaternary-contrast: #000;

  /* set defaults for  text-transform on headings*/
  --headings-transform: 'none';

  /* set defaults for font-family on headings and body text */
  /* this is interfering with Ace editor's cursor position
  --font-headings:  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --font-body-text: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  */

  --neutral-narrow-border: 1px solid rgba(128, 128, 128, 0.3); /* works well on a dark or light background */
  --neutral-border-color: rgba(128, 128, 128, 0.3);
  --neutral-drawer-shadow: 0 0 20px rgba(128, 128, 128, 0.8);

  --editing-drawer-label-highlighted: #007bff;

  /* initial attempt at a default color scheme for our bootstrap flavor */
  --color-primary: #85ce36;
  --color-secondary: #6c757d;
  --color-success: #85ce36;
  --color-danger: #ff4444;
  --color-warning: #fe974b;
  --color-info: #52c9f2;
  --color-light: #efefef;
  --color-dark: ;
}

html body {
  background-color: var(--default-color-background);
  color: var(--default-color-foreground);
}
body,
div,
th,
td {
  font-family: var(--font-body-text);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-headings);
  text-transform: var(--headings-transform);
}

.primary {
  background: var(--palette-color-primary);
  color: var(--palette-color-primary-contrast);
}

.secondary {
  background: var(--palette-color-secondary);
  color: var(--palette-color-secondary-contrast);
}

.tertiary {
  background: var(--palette-color-tertiary);
  color: var(--palette-color-tertiary-contrast);
}

.quaternary {
  background: var(--palette-color-quaternary);
  color: var(--palette-color-quaternary-contrast);
}
.tint {
  background: var(--default-color-tint-background);
}
.tint-2x {
  background: var(--default-color-tint-2x-background);
}

/*** Minor fixes for bootstrap to correctly inherit text colors ***/

.page-header-block {
  background-color: var(--default-color-tint-background);
  border-bottom: var(--neutral-narrow-border);
}

.card {
  border: var(--neutral-narrow-border);
}

.card,
.card .card-body {
  background-color: var(--default-color-background);
}

html .list-group-item {
  background-color: var(--default-color-background);
}

html .modal-content {
  background-color: var(--default-color-background);
}
html .modal-header {
  border-bottom: var(--neutral-narrow-border);
}
/* font-color-neutral - just bleach out 50% of color */
.text-muted {
  color: unset !important;
  opacity: 0.7;
}
.nav-link.disabled {
  color: unset !important;
  opacity: 0.5;
}

/* minor fix for bootstrap to adapt correct theme colors */
.carousel-caption {
  color: unset;
}

.table {
  color: var(--default-color-foreground);
}
.table thead th {
  border-bottom-color: var(--neutral-border-color);
}
.table td,
.table th {
  border-top-color: var(--neutral-border-color);
}
