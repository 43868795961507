.SearchContainer {
    margin-top: 3em;
}
.SearchDrawer .search-result-link:hover {
    text-decoration: none;
}
.SearchDrawer .list-group-item {
    overflow-wrap: break-word;
    word-break: break-word;
}
.SearchDrawer img.search-placeholder {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}
.SearchDrawer .search-control-separator {
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #C8CDD3;
    margin-left: 1rem;
}
.SearchDrawer .search-form>.input-group>.input-group-append>.search-btn {
    border-top-right-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important;
}

.search-spinner {
    font-size: 100px;
    text-align: center;
}

.search-spinner-container {
    text-align: center;
}

.stream-modal img {
    max-width: 100%;
}

.SearchDrawer {
    overflow: auto;
}
.SearchDrawer .label {
    color: #888;
    text-transform: uppercase;
    font-size: 80%;
}
