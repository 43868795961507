html {
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code, kbd {
  font-family: SFMono-Medium,SF Mono,Segoe UI Mono,Roboto Mono,Ubuntu Mono,Menlo,Courier,monospace;
  font-size: 1rem;
  color: var(--default-color-foreground);
}
blockquote {
  border-left: 1px solid #dfe1e6;
  border-left-color: #dfe1e5;
  color: #7a869a;
  margin-left: 19px;
  padding: 10px 20px;
}
blockquote p:last-child {
  margin: 0;
}
pre {
  padding: 10px;
  background: #f0f3f6;
  display: block;
  font-size: 87.5%;
  color: #212529;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  word-wrap: break-word;
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}
.article-body .table-bordered thead td,
.article-body .table-bordered thead th {
  color: #003366;
  background: #f4f5f7;
  font-weight: bold;
  border-bottom-width: 1px;
}
.article-body {
  word-break: break-word;
  overflow-wrap: break-word;
}

/*
.modal-backdrop {
  background-color: rgba(255, 255, 255, 0.5);
}
*/
.modal .modal-content .btn-sm {
  min-width: 82px;
}
.modal .modal-header {
  background-color: #f0f0f0;
}
.modal .modal-title.h4 {
  font-size: 1.25rem;
}

[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1.05em;
  width: 36px;
  height: 36px;
  line-height: 2.3rem;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
  background: #85ce36;
  color: white;
}

/** buttons **/
.btn-success {
  color: #ffffff;
}
.btn-outline-success:hover {
  color: #ffffff;
}
/** nav-tabs styles - underlined - start **/
.nav-tabs {
  border-bottom: 3px solid #ddd;
}

.nav-tabs .nav-item {
  margin-bottom: -3px;
}
.nav-tabs .nav-item .nav-link {
  color: #495057;
  border-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #999;
  color: #3a4651;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link {
  border: 0;
  border-bottom: 3px solid transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: transparent;
  border: 0;
  border-bottom: 3px solid var(--color-primary);
}

.nav-tabs.vertical {
  border-right: 3px solid #ddd;
  border-bottom: none;
  display: block;
}
.nav-tabs.vertical .nav-item {
  margin-bottom: 0;
  margin-right: -3px;
}

.nav-tabs.vertical .nav-link:focus,
.nav-tabs.vertical .nav-link:hover {
  border-color: #999;
  color: #3a4651;
}

.nav-tabs.vertical .nav-link.active,
.nav-tabs.vertical .nav-link:hover {
  background: var(--default-color-tint-background);
}
.nav-tabs.vertical .nav-link,
.nav-tabs.vertical .nav-link {
  border: 0;
  border-right: 3px solid transparent;
}
.nav-tabs.vertical .nav-link.active,
.nav-tabs.vertical .nav-item.show .nav-link {
  border: 0;
  border-right: 3px solid var(--color-primary);
}

/** No content image **/
img.unconfigured-app,
.unconfigured-app svg {
  max-height: 160px;
  max-width: 80%;
  margin: 30px 0;
}

/** nav-tabs styles - underlined - end **/

.inline {
  display: inline;
}

img.asset-placeholder {
  background-color: #f0f0f0;
  background-repeat: no-repeat;
  background-position: 50%;
  vertical-align: bottom;
  border-width: 0;
  background-image: url(/images/svg/asset-placeholder.svg);
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div, p, h1, h2, h3, h4, h5, h6 {
  -moz-hyphens: manual;
  -ms-hyphens: manual;
  -webkit-hyphens: manual;
  hyphens: manual;
}

figure.ac-image {
  display: table;
  text-align: center;
  margin: 1rem auto;
}
figure.align-left {
  float: left;
}
figure.align-right {
  float: right;
}
figure.align-center {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
figure.ac-image .caption {
  display: block;
  font-size: 70%;
  font-weight: 400;
  color: #6c757d;
  margin-top: 3px;
}
figure.ac-image .caption a {
  color: #6c757d;
}

/**
 * Responsive text aligning
 *
 * Adapted from
 * http://ohryan.ca/2014/08/14/set-responsive-text-alignment-bootstrap-3/
 */

/* --breakpoint-sm: 576px;
--breakpoint-md: 768px;
--breakpoint-lg: 992px;
--breakpoint-xl: 1200px; */
.text-xs-left {
  text-align: left;
}
.text-xs-right {
  text-align: right;
}
.text-xs-center {
  text-align: center;
}
.text-xs-justify {
  text-align: justify;
}

/** Data Table styles **/
.rdt_Table .dropdown-item {
  padding: 0.5rem 1.5rem;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-justify {
    text-align: justify;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-justify {
    text-align: justify;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
  .text-lg-justify {
    text-align: justify;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left;
  }
  .text-xl-right {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
  .text-xl-justify {
    text-align: justify;
  }
}

/* --breakpoint-sm: 576px;
--breakpoint-md: 768px;
--breakpoint-lg: 992px;
--breakpoint-xl: 1200px; */
.hidden-xs {
  display: none;
}
.visible-xs {
  display: inline-block;
}

@media (min-width: 576px) {
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .hidden-md {
    display: none;
  }
  .visible-md {
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .hidden-lg {
    display: none;
  }
  .visible-lg {
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .hidden-xl {
    display: none;
  }
  .visible-xl {
    display: inline-block;
  }
}
