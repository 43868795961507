.blanket.menu-drawer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:500;
}

.MenuDrawer {
  position: absolute;
  top: 0;
  right: calc( 100% - 300px );
  left: 0;
  bottom: 0;  
  padding-top: 60px;
  max-height: 100vh;

  background: var(--default-color-background);
  /* opacity: 0.8; */
  z-index:500;
  box-shadow: var( --neutral-drawer-shadow );
}

.MenuDrawer nav {
  border-top: var( --neutral-narrow-border );
  /* padding: 20px; */
}
.MenuDrawer a {
  padding: 15px 20px;
}
.MenuDrawer a.active {
  font-weight: bold;
  background: var(--default-color-tint-background);
}
.MenuDrawer .fa {
  min-width: 26px;
  margin: auto;
  display: inline-block;
}


.MenuDrawer span.fa.fa-home:before {
  font-size: 132%;
}