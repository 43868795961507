/*** Remove all style from these for the time being */

.NotificationsContainer div[class*="Alert-dismissable"],
.NotificationsContainer .AlertContainer {
  max-width: 400px;
}

.NotificationsContainer h4 {
  font-size: 1.1em;
  font-weight: bold;
}

div.alert-dismissable {
  display: block;
  box-shadow: 4px 4px 12px var(--neutral-border-color);
}

.NotificationsContainer button.close {
  opacity: 0.8;
  cursor: pointer;
  margin-top: -1px;
  margin-left: 0.5em;
  color: var(--default-color-foreground);
}
.NotificationsContainer button.close:hover {
  opacity: 1;
}

.NotificationsContainer .alert {
  border-top-color: var(--neutral-border-color);
  border-right-color: var(--neutral-border-color);
  border-bottom-color: var(--neutral-border-color);
}

.NotificationsContainer .alert.alert-primary {
  border-left-color: var(--color-primary);
}
.NotificationsContainer .alert.alert-secondary {
  border-left-color: var(--color-secondary);
}
.NotificationsContainer .alert.alert-success {
  border-left-color: var(--color-success);
}
.NotificationsContainer .alert.alert-danger {
  border-left-color: var(--color-danger);
}
.NotificationsContainer .alert.alert-info {
  border-left-color: var(--color-info);
}
.NotificationsContainer .alert.alert-warning {
  border-left-color: var(--color-warning);
}
.NotificationsContainer .alert.alert-light {
  border-left-color: var(--color-light);
}

.NotificationsContainer .alert {
  background-color: var(--default-color-background) !important;
  color: var(--default-color-foreground);
  border-left-width: 10px;
  /* border-radius: 0; */
}
