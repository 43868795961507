.NotAuthenticatedLayout {
  height: 100%;
  width: 100vw;
  /* background-color: #dee9ff; */
  background-color: #e5ebdd;
}
@media (min-width: 600px) {
  .auth-container .buttons-container {
    margin-bottom: 1.5rem;
  }
  #signin-button {
    width: 49%;
  }
  #register-account {
    width: 49%;
    float: right;
  }
}
.auth-container {
  background-color: #fff;
  max-width: 600px;
  min-width: 600px;
  margin: auto;
  padding: 30px 70px;
  border-radius: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.075);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.auth-container .line {
  height: 1px;
  width: 45%;
  background-color: #E0E0E0;
  margin-top: 10px;
}
.auth-container .or {
  width: 10%;
  font-weight: bold;
  text-transform: capitalize;
}

@media (max-width: 824px) {
  .auth-container {
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 30px 0 0 0;
    position: relative;
    left: 0;
    top: 0;
    -webkit-transform: inherit;
    transform: inherit;
    margin: 0;
  }
  .auth-card-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  #signin-button {
    width: 100%;
    margin-bottom: 1rem;
  }
  #register-account {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}

.logo.full img {
  max-width: 250px;
  max-height: 80px;
  margin: 0;
  -webkit-transition-property: -webkit-transform filter;
  transition-property: -webkit-transform filter;
  -o-transition-property: transform filter;
  transition-property: transform filter;
  transition-property: transform filter, -webkit-transform filter;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.logo.full img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36); /* the transform says "aloha" */
}

.auth-container .auth-content {
  padding: 30px 1em;
}

@media (min-width: 1200px) {
  .auth-container .auth-content {
    padding: 0;
  }
}

footer .logo {
  display: block;
  text-align: center;
  width: 100%;
  height: initial;
  margin-bottom: 20px;
}
footer .logo img {
  width: 120px;
  height: auto;
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);

  -webkit-transition-property: -webkit-transform filter;
  transition-property: -webkit-transform filter;

  transition-property: transform filter;

  transition-property: transform filter, -webkit-transform filter;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
footer .logo img:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  /* transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36); the transform says "aloha" */
}

.MainFooter .footer {
  font-size: 80%;
  padding: 0 50px;
}
.MainFooter .footer a {
  color: rgba(128, 128, 128, 0.8);
}
.MainFooter .footer a:hover {
  color: #6c757d;
}
.MainFooter .footer a:last-child:before {
  margin-left: 5px;
  content: " • ";
  margin-right: 5px;
}
.MainFooter .aloha-footer {
  border-top: var(--neutral-narrow-border);
  max-width: 100%;
}
.MainFooter .default-footer {
  color: rgba(128, 128, 128, 0.8);
}

