/*
.ps-sidebar-root {
    position: fixed !important;
    height: 100%;
    z-index: 100;
    top: 60px;
    left: 0;
    overflow-x: hidden;
    padding-top: 0;
}
*/
.left-menu-item {
    padding: 10px 0;
}
.left-menu-item img, .left-menu-item .ps-menu-label {
    filter: brightness(0) invert(1);
}
.left-menu-item img:hover {
    filter: sepia(300%) hue-rotate(150deg) saturate(450%);
}
.left-menu-item span.ps-menu-label {
    font-weight: bold;
}
.left-menu-item .ps-menu-button:hover img {
    filter: sepia(300%) hue-rotate(150deg) saturate(450%);
}
.left-menu-item .ps-menu-button:hover .ps-menu-label {
    color: #000000;
    filter: none;
}
