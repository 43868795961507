.messaging-outer {
  position: fixed;
  bottom: 0;
  right: 30px;
  z-index: 800;
}

#messagingAccordion {
  max-height: 95vh;
  max-width: 87vw;
}
#collapseMessaging .am-frame {
  background: #f5f6f7;
  max-height: 95vh;
  max-width: 87vw;
}

.messaging-trigger {
  margin-bottom: 0;
  float: right;
  border-radius: 2px 2px 0 0;
  background-color: #f5f6f7;
}

.messaging-trigger.opened {
  width: 395px;
  max-width: 87vw;
}

.messaging-trigger .fa {
  margin-top: 6px;
  font-size: 70%;
}
.messaging-trigger.opened .fa {
  transform: rotate(180deg);
}

.messaging-trigger.closed {
  width: 150px;
}

.messaging-outer .presence-indicator {
  margin-top: 4px;
}

.hidden {
  display: none;
}

#messagingAccordion .card {
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border-radius: 2px 2px 0 0;
}
#messagingAccordion button.btn:focus {
  box-shadow: none;
}

#messagingAccordion .card-header {
  min-height: 0;
  background: none;
}

#messagingAccordion,
#messagingAccordion .card,
#messagingAccordion .card-header {
  border-bottom: 0;
}
